<template>
    <div>
        <ts-panel>
            <ts-panel-wrapper>
                <fieldset class="mt-2">
                    <legend class="text-blue">
                        {{ $t('loanRepayment.loanDisbursementInfo') }}
                    </legend>
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t('loanRepayment.loanType') }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                            >
                                {{
                                    loan.loan_type
                                        ? loan.loan_type.loan_type_en
                                        : '-'
                                }}
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t('loanRepayment.loanAmount') }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                            >
                                {{
                                    loan
                                        ? formatCurrencyWithCode(
                                              loan.loan_amount,
                                              currency
                                          )
                                        : '-'
                                }}
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t('loanRepayment.interestRate') }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                            >
                                {{ loan ? loan.interest_rate : '-' }}%
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t('loanRepayment.insuranceFee') }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 text-decoration-none"
                            >
                                {{
                                    loan
                                        ? formatCurrencyWithCode(
                                              loan.insurance_fee,
                                              currency
                                          )
                                        : ''
                                }}
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t('loanRepayment.adminFee') }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                            >
                                {{
                                    loan
                                        ? formatCurrencyWithCode(
                                              loan.admin_fee,
                                              currency
                                          )
                                        : '-'
                                }}
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t('loanRepayment.repaymentMethod') }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                            >
                                {{
                                    loan.repayment_method
                                        ? loan.repayment_method.method_name_en
                                        : '-'
                                }}
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{
                                        $t('loanRepayment.firstInstallmentDate')
                                    }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"
                            >
                                {{ loan ? loan.first_installment_date : '-' }}
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t('loanRepayment.skipSunday') }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 text-decoration-none"
                            >
                                <span
                                    v-if="loan.is_skip_sunday"
                                    class="badge bg-success tw-mr-1 tw-capitalize"
                                >
                                    Yes
                                </span>
                                <span
                                    v-else
                                    class="badge bg-secondary tw-mr-1 tw-capitalize"
                                >
                                    No
                                </span>
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                    <!-- begin widget-list -->
                    <div class="widget-list rounded">
                        <!-- begin widget-list-item -->
                        <a class="widget-list-item">
                            <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                    {{ $t('loanRepayment.allowPayOffOnCycle') }}
                                </h4>
                            </div>
                            <div
                                class="widget-list-action text-nowrap text-gray-600 text-decoration-none"
                            >
                                {{ loan ? loan.allow_pay_off_on_cycle : '' }}
                            </div>
                        </a>
                        <!-- end widget-list-item -->
                    </div>
                    <!-- end widget-list -->
                </fieldset>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'loanInfo',
    props: ['value'],
    data () {
        return {
            model: {},
            errors: new Errors()
        }
    },
    created () {
        this.model = this.value
    },
    computed: {
        ...mapState('creditOperation/loanRepayment', ['loanDisbursements']),
        ...mapGetters(['formatCurrencyWithCode']),
        loan () {
            if (this.loanDisbursements.length > 0 && this.model.loan_id) {
                return this.loanDisbursements.find(
                    l => l.loan_id == this.model.loan_id
                )
            }
            return []
        },
        currency () {
            if (this.model.loan_id) {
                return this.loan.currency
            }
            return {
                currency_id: this.$store.state.authUser.sys_setting[0]
                    .default_currency_id,
                currency_code: this.$store.state.authUser.sys_setting[0]
                    .default_currency
            }
        }
    }
}
</script>
